<div class="panel">
  <div class="lights toggleable">
    <h2>Light Controls</h2>
    <h3>Directional Light</h3>
    <div><label for="light-rotation-y">Y axis rotation</label></div>
    <mat-slider
      id="light-rotation-y"
      [min]="minYRotation"
      [max]="maxYRotation"
      [step]="rotationStep"
      [value]="-directionalLightRotation.y"
      (input)="onLightRotation($event, 'y')"
      (change)="onControlChange()"
    ></mat-slider>
    <div><label for="light-rotation-z">Z axis rotation</label></div>
    <mat-slider
      id="light-rotation-z"
      [min]="minZRotation"
      [max]="maxZRotation"
      [step]="rotationStep"
      [value]="-directionalLightRotation.z"
      (input)="onLightRotation($event, 'z')"
      (change)="onControlChange()"
    ></mat-slider>
    <div>
      <label for="direction-intensity-slider">Intensity</label>
    </div>
    <mat-slider
      id="direction-intensity-slider"
      [min]="minDirectionalLightIntensity"
      [max]="maxDirectionalLightIntensity"
      [step]="directionalLightIntensityStep"
      [value]="directionalLightIntensity"
      (input)="onLightIntensityChange($event.value)"
      (change)="onControlChange()"
    ></mat-slider>
    <h3>Ambient Light</h3>
    <div><label for="ambient-intensity-slider">Intensity</label></div>
    <mat-slider
      id="ambient-intensity-slider"
      [min]="minAmbientLightIntensity"
      [max]="maxAmbientLightIntensity"
      [step]="ambientLightIntensityStep"
      [value]="ambientLightIntensity"
      (input)="onAmbientLightIntensityChange($event.value)"
      (change)="onControlChange()"
    ></mat-slider>
  </div>
</div>
<div class="panel object toggleable">
  <h2>Object Controls</h2>
  <h3>Rotation</h3>
  <div><label for="obj-rotation-yaw">Yaw (y axis)</label></div>
  <mat-slider
    id="obj-rotation-yaw"
    [min]="minYRotation"
    [max]="maxYRotation"
    [step]="rotationStep"
    [value]="-objectRotation.y"
    (input)="onObjectRotation($event, 'y')"
    (change)="onControlChange()"
  ></mat-slider>
  <div><label for="obj-rotation-pitch">Pitch (z axis)</label></div>
  <mat-slider
    id="obj-rotation-pitch"
    [min]="minZRotation"
    [max]="maxZRotation"
    [step]="rotationStep"
    [value]="-objectRotation.z"
    (input)="onObjectRotation($event, 'z')"
    (change)="onControlChange()"
  ></mat-slider>
  <div><label for="obj-rotation-roll">Roll (x axis)</label></div>
  <mat-slider
    id="obj-rotation-roll"
    [min]="minXRotation"
    [max]="maxXRotation"
    [step]="rotationStep"
    [value]="objectRotation.x"
    (input)="onObjectRotation($event, 'x')"
    (change)="onControlChange()"
  ></mat-slider>
</div>
