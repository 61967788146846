<button class="collapse" (click)="toggleGui()">
  {{ guiVisible ? "▼ Collapse ▼" : "▲ Expand ▲" }}
</button>
<div class="collapsible" [class.collapsed]="!guiVisible">
  <div class="credit">{{ (appService.currentModel$ | async)?.credit }}</div>
  <mat-progress-bar
    class="loader"
    mode="indeterminate"
    *ngIf="appService.modelLoading$ | async"
  ></mat-progress-bar>
  <model-selector
    [models]="models"
    [loading]="appService.modelLoading$ | async"
  ></model-selector>
</div>
