<mat-toolbar color="primary">
  <span>Drawing References</span>
  <span class="toolbar-spacer"></span>
  <button
    mat-icon-button
    color="accent"
    aria-label="Toggle visibility of controls"
    [matTooltip]="hud.hidden ? 'Show controls' : 'Hide controls'"
    (click)="hud.toggleVisibility()"
  >
    <mat-icon>{{ hud.hidden ? "visibility_off" : "visibility" }}</mat-icon>
  </button>
  <button
    mat-icon-button
    color="accent"
    aria-label="Options"
    matTooltip="Options"
    [matMenuTriggerFor]="optionsMenu"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #optionsMenu>
    <button mat-menu-item (click)="hud.reset()">
      <mat-icon>restart_alt</mat-icon>
      <span>Reset controls</span>
    </button>
    <button mat-menu-item (click)="share()">
      <mat-icon>ios_share</mat-icon>
      <span>Share (copy link to clipboard)</span>
    </button>
  </mat-menu>
</mat-toolbar>
<canvas #canvas></canvas>
<hud
  [class.hidden]="!init"
  [directionalLightRotation]="lightTransform.rotation"
  [(directionalLightIntensity)]="light.intensity"
  [(ambientLightIntensity)]="ambientLight.intensity"
  [objectRotation]="modelTransform.rotation"
  #hud
></hud>
<gui [models]="models"></gui>
